/**
 * Reset Globally
 * Auth: Asif Raza
 * 
 * =================================
 * We use normalize.css for the bulk of our 'reset'
 */

@import 'normalize';
// https://github.com/necolas/normalize.css

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@include normalize();
