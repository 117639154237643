@charset "UTF-8";

// Import base system
@import './globals/reset';
@import './globals/variables';
@import './globals/grid';
@import './globals/typography';
@import './globals/base';
// @import './globals/utilities';

@font-face {
  font-family: 'Jura';
  src: url('/fonts/Jura/static/Jura-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Jura';
  src: url('/fonts/Jura/static/Jura-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Jura';
  src: url('/fonts/Jura/static/Jura-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Jura';
  src: url('/fonts/Jura/static/Jura-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Jura';
  src: url('/fonts/Jura/static/Jura-Bold.ttf') format('truetype');
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 0;
}

body {
  // visibility: hidden;
  background: #000418;
  scroll-behavior: smooth;
}

.carousel {
  width: 100dvw;
  height: max-content;
  // height: max-content;
  position: relative;
}

.carousel .carousel-inner .carousel-item {
  visibility: visible;
  position: relative;
  height: max-content;
  width: 100dvw;
  height: 100%;
}

.RoadMapBtnSelected {
  background: url('/images/roadmap/Modal/btnBorderColor.webp');
  transition: background-image 150ms ease-in-out;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 88px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: rgba(230, 230, 230, 1);
  border: none;
  border-radius: 8px;
}

.RoadMapBtnHighlight {
  background: rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: background-color 150ms ease-out;

  width: 88px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: rgba(4, 2, 23, 1);

  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 8px;
}

.RoadMapBtnHighlight:hover {
  background: linear-gradient(
      0deg,
      rgba(174, 122, 196, 0.2) 0%,
      rgba(174, 122, 196, 0.2) 100%
    ),
    #fff;
}

.RoadMapBtnHighlight:active {
  background: linear-gradient(
      0deg,
      rgba(174, 122, 196, 0.4) 0%,
      rgba(174, 122, 196, 0.4) 100%
    ),
    #fff;
}

.LaunchNavBtn {
  width: 148px;
  height: 48px;
  // padding: 16px 24px 16px 24px;
  border-radius: 8px;
  background: var(
    --BTN-Background,
    linear-gradient(
      180deg,
      rgba(254, 200, 241, 0.9) -22.92%,
      rgba(254, 200, 241, 0) 26.73%
    ),
    radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602ea6 0%, #c977d6 100%)
  );
  background-blend-mode: overlay, normal;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    color: #e6e6e6;
    text-align: center;
    font-family: var(--font-circularstdbook);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    cursor: pointer;
    white-space: nowrap;
  }

  /* 16px */
  transition: background-image 0.5s ease-in;
  cursor: pointer;
}

.LaunchNavBtn:hover {
  background: linear-gradient(
      180deg,
      rgba(254, 200, 241, 0.9) -22.92%,
      rgba(254, 200, 241, 0) 79.14%
    ),
    radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602ea6 0%, #c977d6 100%);
}

.LaunchNavBtn:active {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602ea6 0%, #c977d6 100%);
}

.RoadMapBtnOutline {
  background-color: transparent;
  cursor: pointer;
  transition: background-color 150ms ease-out;
  width: 88px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: rgba(230, 230, 230, 1);
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 8px;
  opacity: 0.6;
}

.RoadMapBtnOutline:hover {
  background-color: rgba(174, 122, 196, 0.2);
}

.RoadMapBtnOutline:active {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    rgba(174, 122, 196, 0.2);
}

.ptv {
  font-family: 'Circular Std';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  background: var(
    --Gradient-accent,
    linear-gradient(90deg, #45d3e8 0%, #0cf54e 105.38%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &.kol {
    line-height: 140%;
  }
}

.text-glow {
  text-shadow: 0px 0px 25px #91EBFF;
}

.app_container {
  main {
    overflow-x: hidden;
  }

  .container {
    width: 100%;
    margin: 0 auto;

    &.kol {
      max-width: 1560px;
    }
  }

  .top-section {
    background: url(/images_p/top-section-bg.webp) no-repeat top center;
    position: relative;
    background-size: cover;
    min-height: max-content;

    &.kol-page {
      background: rgb(26, 20, 46) !important;
      background: linear-gradient(
        113deg,
        rgba(26, 20, 46, 1) 0%,
        rgba(44, 28, 77, 1) 99%
      );
    }

    &.con-page {
      background: transparent;
    }

    .timer {
      // padding: 0 5%;
      padding: 5px 5% 5px 5%;
    }

    .liner-shade {
      // padding: 0 0 10px 0; // removed because above timer bg has already space above and below
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      flex-direction: column;
      position: relative;

      &.con-page {
        background: transparent;
      }
    }
  }

  .get-started-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto 12px 0;
    max-width: 1400px;
    // padding: 20px 0;
    gap: 40px;
    margin: 0 auto;
    height: 112px;

    border-radius: 12px;
    background-size: 100% 100%;
    background-image: url(/images_p/timer-bg.webp);
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
  }

  .signup-txt {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-family: var(--font-circularstdbook);
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 100%;
    padding-bottom: 10px;
  }

  .time-box-main {
    display: flex;
    justify-content: center;
    font-family: var(--font-circularstdmedium);
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    min-width: 250px;
  }

  .time-box-main span {
    margin: 0 10px;
  }

  .days span,
  .hrs span,
  .mins span,
  .secs span {
    display: block;
    margin: 0;
  }

  .time-box-main span.sm {
    font-size: 10px;
    text-transform: uppercase;
  }

  .get-started-btn a {
    display: flex;
    padding: 14px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #fff;
    color: #040217;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 8px;
  }

  .slider-bar {
    display: flex;
    gap: 20px;
    margin-top: 40px;

    &.safari {
      padding-bottom: 1px;
    }

    span.active {
      width: 112px;
      height: 3px;
      flex-shrink: 0;
      border-radius: 3px;
      // background: rgba(230, 230, 230, 0.8);
      background: #e6e6e6;
    }

    span {
      width: 112px;
      height: 3px;
      flex-shrink: 0;
      border-radius: 3px;
      background: rgba(230, 230, 230, 0.6);
    }
  }

  // Hero Section
  // ===========================================
  .main-banner .container {
    display: flex;
    align-items: center;
    // width: auto;
  }

  .main-banner {
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    height: 786px;
    min-height: max-content;
    position: relative;
  }

  .main-banner .liner-shade {
    padding: 213px 0 285px;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 6, 30, 0.7), rgba(0, 6, 30, 0.7)),
      linear-gradient(180deg, #00061e 0%, rgba(0, 6, 30, 0) 50.5%, #00061e 100%);
    // z-index: -1;
    display: flex;
  }

  .main-banner .banner-img .hand {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 57%;
    height: 100%;
    object-fit: contain;
    z-index: 10;
  }

  .main-banner .banner-img .Solana_3D {
    position: absolute;
    bottom: 33%;
    right: 20%;
    width: 393px;
    height: 393px;
    object-fit: contain;
    z-index: 20;
    animation: bounce 2.6s infinite ease-in-out;
  }

  .main-banner .banner-img .Avalanche_3D {
    position: absolute;
    bottom: 45%;
    right: 14%;
    width: 221px;
    height: 221px;
    object-fit: contain;
    z-index: 20;
    animation: bounce 2.3s infinite ease-in-out;
  }

  .main-banner .banner-img .USD_Coin_3D_1 {
    position: absolute;
    bottom: 62%;
    right: 12%;
    width: 199px;
    height: 199px;
    object-fit: contain;
    z-index: 20;
    animation: bounce 2.5s infinite ease-in-out;
  }

  .main-banner .banner-img .USD_Coin_3D {
    position: absolute;
    bottom: 68%;
    right: 18%;
    width: 235px;
    height: 235px;
    object-fit: contain;
    z-index: 20;
    animation: bounce 2.7s infinite ease-in-out;
  }

  .main-banner .banner-img .Polygon_3D {
    position: absolute;
    bottom: 74%;
    right: 28%;
    width: 173px;
    height: 173px;
    object-fit: contain;
    z-index: 20;
    animation: bounce 2.7s infinite ease-in-out;
  }

  .main-banner .banner-img .Bitcoin_3D {
    position: absolute;
    bottom: 60%;
    right: 35%;
    width: 231px;
    height: 231px;
    object-fit: contain;
    z-index: 20;

    animation: bounce 2.4s infinite ease-in-out;
  }

  .main-banner .banner-img svg {
    position: absolute;
    width: 701px;
    height: 893px;
    bottom: 0;
    right: 0;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  .main-banner .txt-box {
    font-size: 20px;
    color: #e6e6e6;
    font-family: var(--font-circularstdbook);
    max-width: 728px;
  }

  .main-banner .txt-box h1 {
    font-size: 96px;
    color: #e6e6e6;
    font-family: var(--font-circularstdmedium);
    margin: 0 0 18px;
    padding: 0;
    letter-spacing: 3px;
    font-weight: 700;
  }

  .main-banner .txt-box h4 {
    font-size: 36px;
    color: #e6e6e6;
    font-family: var(--font-circularstdmedium);
    margin: 0 0 16px;
    padding: 0;
  }

  .main-banner .txt-box p {
    // color: rgba(230, 230, 230, 0.7);
    // // font-family: var(--font-circularstdmedium);
    // font-family: 'Poppins', sans-serif; // temp added
    // font-weight: 450;
    color: rgba(230, 230, 230, 0.7);
    font-family: 'Poppins', sans-serif;
    font-weight: 450;
    text-align: justify;
  }

  a.banner-btn {
    font-size: 16px;
    color: #e6e6e6;
    font-family: var(--font-circularstdmedium);
    padding: 9.5px 79px;
    border-radius: 10px;
    margin-right: 22px;
    border: 1px solid transparent;
    margin-top: 50px;
    display: inline-block;
    background: var(
      --BTN-Background,
      linear-gradient(
        180deg,
        rgba(254, 200, 241, 0.9) -22.92%,
        rgba(254, 200, 241, 0) 26.73%
      ),
      radial-gradient(
        137.13% 253.39% at 76.68% 66.67%,
        #602ea6 0%,
        #c977d6 100%
      )
    );
    background-blend-mode: overlay, normal;
    box-shadow: 0px -1px 8px 0px #9375b6 inset, 0px 0px 5px 0px #fface4 inset,
      0px 30px 30px 0px rgba(0, 0, 0, 0.4);
  }

  .menu-icon {
    display: none;
  }

  .menu-icon-coress {
    display: none;
  }

  // Meet trading
  // ==================================

  .trading-meets-section {
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    // height: 100dvh;
    min-height: max-content;
    position: relative;
    text-align: center;

    .liner-shade {
      padding: 186px 0 80px;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      // z-index: -1;
      display: flex;
    }

    h3 {
      font-size: 64px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0;
      margin-bottom: -80px;
      position: relative;
      z-index: 10;
    }

    .img-box {
      z-index: 8;
    }

    // forcefully align center the image
    .img-box img {
      margin-left: -6%;
    }

    img.responsive {
      width: 120%;
      height: auto;
    }
  }

  // Eco System
  // =======================================
  .pt-eco-system-section {
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    // height: 100dvh;
    min-height: max-content;
    position: relative;

    .liner-shade {
      // padding: 200px 0 80px;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      // z-index: -1;
      display: flex;
    }

    .container {
      padding: 150px 10px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      text-align: center;
      label {
        color: #e6e6e6;
        text-align: center;
        text-shadow: 0px 0px 20px #45d3e8;
        font-family: var(--font-circularstdmedium);
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 64px */
      }
      max-width: 1500px;
    }

    img.responsive {
      width: 100%;
      height: auto;
    }
  }

  // How to trade
  // ======================================
  .how-prime-section {
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    // height: 100dvh;
    min-height: max-content;
    position: relative;

    .container {
      display: flex;
      padding: 125px 0;
    }

    img.responsive {
      width: 100%;
      height: auto;
    }

    .liner-shade {
      // padding: 200px 0 80px;
      width: 100%;
      height: 100%;
      min-height: 100dvh;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;
    }

    .slider-bar {
      display: flex;
      gap: 20px;
      margin-bottom: 40px;
      margin-left: 3px;

      span {
        width: 80px;
        height: 2px;
        border-radius: 2.138px;
        background: rgba(230, 230, 230, 0.6);
      }

      span.active {
        width: 80px;
        opacity: 1;
        height: 2px;
        border-radius: 4px;
        background: #fff;
      }

      span > span {
        display: block;
        width: 25px;
        border-radius: 2.138px;
        background: var(
          --Btn,
          linear-gradient(97deg, #6554c0 -0.11%, #0065ff 99.89%)
        );
        position: relative;
        left: -1px;
      }
    }

    background-size: cover;
    // padding: 162px 0 238px;
    font-size: 20px;
    color: #fff;
    font-family: var(--font-circularstdmedium);
    line-height: normal;

    h3 {
      font-size: 64px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0 0 37px;
      width: 717px;

      // As per figma
      // text-wrap: nowrap;
    }

    h6 {
      font-size: 32px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0;
    }

    @keyframes slideRightToLeft {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }

    @keyframes slideRightToLeftAndStay {
      0% {
        transform: translateX(100%);
      }
      37.5% /* Equivalent to 3s in an 8s animation */
      , 100% {
        transform: translateX(0);
      }
    }

    @keyframes slideRightToLeftAndOut {
      0%,
      100% {
        transform: translateX(100%); /* Start and end off-screen to the right */
      }
      27% {
        /* Roughly 3 seconds in to start showing */
        transform: translateX(0);
      }
      73% {
        /* Starts to stay visible, for 5 seconds */
        transform: translateX(0);
      }
      82% {
        /* Begin sliding out after the pause */
        transform: translateX(-100%);
      }
    }

    .flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // gap for this section
      // gap: 190px;
      gap: 120px;
    }

    .flex-box-single {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .txt-box {
      max-width: 717px;
    }

    .txt-box ul {
      padding-left: 20px;
      max-width: 633px;
    }

    .txt-box li {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      margin-bottom: 20px;
      // text-wrap: balance;
      list-style-type: disc;
    }

    .img-box {
      width: 50%;
      text-align: center;
      position: relative;
      padding: 5% 0 0 0;
    }

    .spider-graph {
      background-image: url(/images/graph-bg-how-to-trade.png);
      background-size: 100% 100%;
      // height: 100dvh;
      max-width: 100dvw;
      background-repeat: no-repeat;
      width: 100dvw;
      padding: 0 178px;
    }

    // .spider-graph > svg,
    // img {
    //   width: 100%;
    //   height: 100%;
    // }

    .countries-box {
      flex-shrink: 0;
      border-radius: 21.377px;
      border: 2.138px solid rgba(255, 255, 255, 0.2);
      background: radial-gradient(
        151.92% 127.02% at 15.32% 21.04%,
        rgba(179, 121, 223, 0.2) 0%,
        rgba(204, 88, 84, 0.02) 77.08%,
        rgba(179, 121, 223, 0.2) 100%
      );
      backdrop-filter: blur(42.75409698486328px);
      -webkit-text-stroke: 0.8px rgba(165, 234, 252, 0.5);
      padding: 36px 45px;
      border-radius: 21px;
      font-family: var(--font-circularstdbook);
      font-size: 15px;
      color: #e6e6e6;
      position: absolute;
      right: 25px;
      bottom: 6px;
      width: 160px;
      height: 160px;
      padding: 30px 0 0;
    }

    .countries-box.total-join {
      right: auto;
      left: -48px;
      top: 35%;
      bottom: auto;
      z-index: 5;
      font-size: 14.308px;
      font-family: var(--font-circularstdbook);
    }

    .countries-box span.digits {
      color: #3b2646;
      text-align: center;
      // -webkit-text-stroke-width: 0.5961538553237915;
      // -webkit-text-stroke-color: rgba(165, 234, 252, 0.4);
      -webkit-text-stroke: 0.59px rgba(165, 234, 252, 0.5);
      font-family: var(--font-circularstdbook);
      font-size: 57.3px;
      font-style: normal;
      font-weight: 500;
      display: block;
      text-shadow: 0 0 10px #a5eafc;
    }

    .countries-box span.digits-title {
      color: #e6e6e6;
      text-align: center;
      // font-family: 'slick';
      font-size: 14.308px;
      font-style: normal;
      font-weight: 100;
      line-height: 120%; /* 17.169px */
    }
  }

  // How to trade Mobile
  // ======================================
  .how-prime-section_mob {
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    // height: 100dvh;
    min-height: max-content;
    position: relative;

    .liner-shade {
      // padding: 200px 0 80px;
      width: 100%;
      height: 100%;
      min-height: 100dvh;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;
    }

    .spider-graph {
      // background-image: url(/images/spider_mob.png);
      // background-size: 100% 100%;
      // height: 100dvh;
      max-width: 100dvw;
      // height: 1300px; // fix layout shift on due to content of sliders
      background-repeat: no-repeat;
      width: 100dvw;
      padding: 100px 24px 0px 24px;

      .slider-bar {
        display: flex;
        gap: 20px;
        margin-top: 40px;
        align-items: center;
        justify-content: center;
      }

      .content {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .content-box {
          width: 327px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .mainHeading {
          text-align: center;

          label {
            color: #e6e6e6;
            text-align: center;
            text-shadow: 0px 0px 20px #45d3e8;
            font-family: var(--font-circularstdbook);
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 33.6px */
          }
          p {
            color: #e6e6e6;
            text-align: center;
            font-family: var(--font-circularstdbook);
            font-size: 14px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%; /* 16.8px */
          }
        }

        .stepsCount {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-bottom: 18px;
          margin-top: -16px;
        }

        .titlePoints {
          margin-top: 16px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          gap: 24px;

          label {
            color: #e6e6e6;
            text-align: center;
            text-shadow: 0px 0px 20px #45d3e8;
            font-family: var(--font-circularstdbook);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 28.8px */
          }
          p {
            color: #e6e6e6;
            text-align: center;
            font-family: var(--font-circularstdbook);
            font-size: 14px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%; /* 16.8px */
          }
        }

        .bullentsPoints {
          width: 100%;
          padding-left: 22px;

          ul {
            li {
              font-family: Jura;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: justify;
            }
          }
        }
      }
    }
  }

  // RoadMap Mobile
  // ======================================
  .roadmap-section_mob {
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    // height: 100dvh;
    // min-height: max-content;
    height: 670px;
    position: relative;

    .liner-shade {
      // padding: 200px 0 80px;
      width: 100%;
      height: 100%;
      min-height: 100dvh;

      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;
      padding: 0 11px 0 11px;
      width: 100dvw;
    }

    .content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .mainHeading {
        text-align: center;

        label {
          font-family: var(--font-circularstdbook);
          font-size: 36px;
          font-weight: 500;
          line-height: 43.2px;
          text-align: center;
          color: rgba(230, 230, 230, 1);
        }
      }

      .btnCon {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 16px;
      }

      .cardCon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .col {
            width: 167px;
            height: 247px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .stepsCount {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 16px 0;

        .step {
          width: 80px;
          height: 2px;
          border-radius: 4px;
          opacity: 0.4;
          background: rgba(255, 255, 255, 1);
        }

        .selected {
          opacity: 1;
        }

        .center {
          margin: 0 14px;
        }
      }

      .details {
        margin: 20px 0;

        label {
          font-family: Jura;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: justify;
          color: rgba(230, 230, 230, 1);
          width: 100%;
        }
      }
    }
  }
  // Media write about us Mobile
  // ======================================
  .media-write-section_mob {
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    min-height: max-content;
    position: relative;

    .section-header {
      width: 100%;

      h3 {
        color: #e6e6e6;
        text-align: center;
        text-shadow: 0px 0px 20px #45d3e8;
        font-family: var(--font-circularstdbook);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 28.8px */
      }
    }

    .liner-shade {
      // padding: 200px 0 80px;
      width: 100%;
      height: 100%;

      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;
    }

    .content-container {
      max-width: 100dvw;
      width: 100dvw;
      padding: 100px 24px 0 24px;

      .slider-bar {
        display: flex;
        gap: 20px;
        margin-top: 40px;
        align-items: center;
        justify-content: center;
      }

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .content-wrapper {
          display: flex;
          flex-direction: column;
          padding: 1.2px;
          background-image: linear-gradient(
              217deg,
              rgb(165 239 255 / 80%),
              rgb(70 144 212 / 0%) 70.71%
            ),
            linear-gradient(
              127deg,
              rgb(152 249 255 / 80%),
              rgb(255 255 255 / 0%) 70.71%
            ),
            linear-gradient(
              336deg,
              rgb(131 80 215 / 80%),
              rgb(135 38 183 / 0%) 70.71%
            );
          background-clip: border-box; /* Ensure the gradient applies to the border area */
          border-radius: 25px; /* Optional: for rounded corners */

          .content-box {
            position: relative;
            height: 100%;
            z-index: 1; /* Ensure the content is above the gradient */
            border-radius: 25px; /* Optional: to match rounded corners */
            background-size: 105%;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 25px;
            padding: 25px;
            background: url('/images_p/MediaWriteAboutUs/card-bg.webp');
            background-blend-mode: overlay, normal;
            background-size: 125% 200%;
            background-position: center;
            backdrop-filter: blur(40px);

            p {
              color: #e6e6e6;
              text-align: justify;
              font-family: var(--font-circularstdbook);
              font-size: 10px;
              font-style: normal;
              font-weight: 450;
              line-height: 120%; /* 12px */
            }

            .read-more-button {
              cursor: pointer;
              width: fit-content;
              border-radius: 8px;
              height: 29px;
              padding: 16px 24px;
              background: var(
                --BTN-Background,
                linear-gradient(
                  180deg,
                  rgba(254, 200, 241, 0.9) -22.92%,
                  rgba(254, 200, 241, 0) 26.73%
                ),
                radial-gradient(
                  137.13% 253.39% at 76.68% 66.67%,
                  #602ea6 0%,
                  #c977d6 100%
                )
              );
              background-blend-mode: overlay, normal;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: background-image 0.5s ease-in;
              cursor: pointer;

              label {
                color: #e6e6e6;
                text-align: center;
                font-family: var(--font-circularstdbook);
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 10px */
                margin-bottom: 0;
              }
            }

            .main-heading {
              display: flex;
              justify-content: space-between;
              gap: 20px;
              h4 {
                color: #e6e6e6;
                text-align: right;
                font-family: var(--font-circularstdbook);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; /* 19.2px */
                margin: 5px 0;
              }

              img {
                width: 57px;
                height: 57px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
  // Core Team Mobile
  // ======================================
  .core_team_mob {
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    // height: 100dvh;
    min-height: max-content;
    position: relative;

    .liner-shade {
      // padding: 200px 0 80px;
      width: 100%;
      height: 100%;
      min-height: 100dvh;

      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 0 11px 0 11px;
      width: 100dvw;
    }

    .content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .mainHeading {
        text-align: center;

        label {
          font-family: var(--font-circularstdbook);
          font-size: 36px;
          font-weight: 500;
          line-height: 43.2px;
          text-align: center;
          color: rgba(230, 230, 230, 1);
        }
      }

      .subHeading {
        text-align: center;

        label {
          font-family: var(--font-circularstdbook);
          font-size: 14px;
          font-weight: 500;
          line-height: 16.8px;
          text-align: center;

          color: rgba(230, 230, 230, 1);
        }
      }
    }

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }

    .titleBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 239px;
      margin: 16px 0;

      &label {
        font-family: var(--font-circularstdbook);
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        text-align: center;

        color: rgba(255, 255, 255, 1);
      }
    }

    .teamBlur {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      max-width: 100dvw;
    }

    .teamCon {
      display: flex;
      align-items: center;
      overflow-x: hidden;
      max-width: 100dvw;
      width: fit-content;
      justify-content: center;

      fade {
        position: absolute;
        left: 0px;

        display: block;

        width: 15px;
        height: 100%;

        background: linear-gradient(90deg, #161616 0.8%, transparent 70%);
        z-index: 2000;
      }

      fader {
        position: absolute;
        right: 0px;

        display: block;

        width: 15px;
        height: 100%;
        background: linear-gradient(90deg, #000418 1.8%, transparent 100%);
        transform: scaleX(-1);
        z-index: 2000;
      }
    }

    .scrollBar {
      width: 284.91px;
      height: 3.15px;
      border-radius: 50px;
      background: rgba(57, 62, 101, 0.5);
      position: relative;
      margin-top: 39px;

      .sliderBar {
        width: 49.98px;
        height: 3.15px;
        border-radius: 50px;
        background: rgba(92, 166, 208, 1);

        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  // Getting started section
  // ===============================================
  .get-started-section {
    text-align: center;
    font-family: var(--font-circularstdbook);
    font-size: 20px;
    color: #e6e6e6;
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    // height: 100dvh;
    min-height: 1180px;
    height: 1080px;
    position: relative;

    .container0 {
      min-height: 700px;
      max-width: fit-content;
      height: max-content;

      .myOwnConSlider {
        background-image: url('/images/gettingStart/signup.webp');
        background-repeat: no-repeat;
        background-position: center;
      }

      .EarnSlide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        z-index: 10;

        .imgTop {
          width: 792px;
          height: 603px;
          position: relative;

          .backDrop {
            width: 563.482px;
            // height: 563.482px;
            height: 310.482px;
            transform: rotate(75deg);
            flex-shrink: 0;
            border-radius: 562.755px;
            opacity: 0.3;
            background: var(
              --BTN-Figma,
              linear-gradient(
                180deg,
                rgba(254, 200, 241, 0.9) -22.92%,
                rgba(254, 200, 241, 0) 26.73%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #602ea6 0%,
                #c977d6 100%
              )
            );
            background-blend-mode: overlay, normal;
            filter: blur(100px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 20;
          }

          img {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 25;
          }
        }
      }

      .signupSlide {
        max-width: 1561px;
        width: 96dvw;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin: auto;
        .SignupBox {
          // max-width: 600px;
          // background: rgba(255, 255, 255, 0.06);
          padding: 40px;
          text-align: left;
          font-family: var(--font-circularstdbook);
          font-size: 20px;
          color: #e6e6e6;
          position: relative;
          z-index: 10;
          border-radius: 12px;
          // border: 2px solid var(--BTN-Stroke, rgba(0, 0, 0, 0.5));
          // background: rgba(255, 255, 255, 0.06);

          backdrop-filter: blur(42.755001068115234px);
          background: url('/images/Frame 481748.webp');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 603px;
        }

        .SignupBox ul {
          padding: 0;
          margin: 0 0 0 20px;
        }

        .SignupBox li {
          margin-bottom: 20px;
          // font-family: 'Poppins', sans-serif;
          // font-weight: 400;
          list-style-type: disc;
          color: #e6e6e6;
          font-family: var(--font-circularstdmedium);
          font-size: 20px;
          font-style: normal;
          font-weight: 450;
          line-height: 120%; /* 24px */
        }

        .SignupBox a {
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          color: #e6e6e6;
          padding: 10.5px 227px;
          margin-top: 20px;
          display: block;
          border-radius: 8px;
          border: 2px solid var(--BTN-Stroke, rgba(0, 0, 0, 0.5));
          background: var(
            --BTN-Background,
            linear-gradient(
              180deg,
              rgba(254, 200, 241, 0.9) -22.92%,
              rgba(254, 200, 241, 0) 26.73%
            ),
            radial-gradient(
              137.13% 253.39% at 76.68% 66.67%,
              #602ea6 0%,
              #c977d6 100%
            )
          );
          background-blend-mode: overlay, normal;
          /* BTN Effects */
          box-shadow: 0px -1px 8px 0px #9375b6 inset,
            0px 0px 5px 0px #fface4 inset;
        }
      }

      .TradeSlide {
        width: 916px;
        height: 684px;
        position: relative;
        margin: auto;

        img {
          position: absolute;
          bottom: 0;
          left: 50%;
          max-width: 916px;
          max-height: 684px;

          transform: translateX(-50%);
        }
      }
    }

    .liner-shade {
      // padding: 200px 0 80px;
      width: 100%;
      height: 100%;
      // padding: 200px 0;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      // z-index: -1;
      display: flex;
      position: relative;
      padding-top: 100px;
      place-content: center;
    }

    .flex-box,
    .flex-box-single {
      transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 300px;
    }

    .flex-box-single {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 700px;
    }

    h3 {
      font-size: 64px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0 0px 12px 0px;
      margin: 0;
    }

    ul.signup-list {
      padding: 0;
      margin: 40px 0 10px;
    }

    ul.signup-list li {
      list-style: none;
      display: inline-block;
      margin: 0 10px;
    }

    ul.signup-list li a {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      color: #e6e6e6;
      padding: 10.5px 23px;
      border: 2px solid #fff;
      border-radius: 8px;
    }

    ul.signup-list li a:hover,
    ul.signup-list li a.active {
      background: var(
        --BTN-Background,
        linear-gradient(
          180deg,
          rgba(254, 200, 241, 0.9) -22.92%,
          rgba(254, 200, 241, 0) 26.73%
        ),
        radial-gradient(
          137.13% 253.39% at 76.68% 66.67%,
          #602ea6 0%,
          #c977d6 100%
        )
      );
      background-blend-mode: overlay, normal;
      border-color: transparent;
    }

    .img-box {
      max-width: 45%;
      text-align: center;
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: center;
    }

    .img-box .geting-started-image {
      margin-right: 22%;
    }

    // .img-box img {
    //   width: 100%;
    //   height: auto;
    // }

    h4 {
      font-size: 26px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0 0 20px;
    }

    a.btn {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      color: #e6e6e6;
      padding: 10.5px 227px;
      border: 2px solid transparent;
      border-radius: 8px;
      background: var(
        --BTN-Background,
        linear-gradient(
          180deg,
          rgba(254, 200, 241, 0.9) -22.92%,
          rgba(254, 200, 241, 0) 26.73%
        ),
        radial-gradient(
          137.13% 253.39% at 76.68% 66.67%,
          #602ea6 0%,
          #c977d6 100%
        )
      );
      background-blend-mode: overlay, normal;
      border-color: transparent;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }

    a:hover.btn {
      background: none;
      border-color: #fff;
    }
  }

  // Bridge Section
  // ================================================

  .bridging-sections {
    text-align: center;
    font-family: var(--font-circularstdbook);
    font-size: 20px;
    color: #e6e6e6;
    position: relative;
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    min-height: max-content;

    .liner-shade {
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;
    }

    .liner-shade-bridge {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 6, 30, 1), rgba(0, 6, 30, 0.8)),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;
    }

    .flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // special padding
      padding: 86px 0;
    }

    .txt-box {
      max-width: 50%;
      text-align: left;
      font-family: var(--font-circularstdbook);
      font-size: 20px;
      color: #e6e6e6;
    }

    h3 {
      font-size: 48px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0 0 20px;
    }

    img.responsive {
      width: 100%;
      height: auto;
    }

    a {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      color: #e6e6e6;
      padding: 10.5px 23px;
      border: 2px solid #fff;
      border-radius: 8px;
      background: var(
        --BTN-Background,
        linear-gradient(
          180deg,
          rgba(254, 200, 241, 0.9) -22.92%,
          rgba(254, 200, 241, 0) 26.73%
        ),
        radial-gradient(
          137.13% 253.39% at 76.68% 66.67%,
          #602ea6 0%,
          #c977d6 100%
        )
      );
      background-blend-mode: overlay, normal;
      border-color: transparent;
      display: inline-block;
      margin-top: 2px;

      &:hover {
        background: none;
        border-color: #fff;
      }
    }

    .web3-p {
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      // font-family: 'slick';
      font-size: 20px;
      font-style: normal;
      // font-weight: 450;
      line-height: 120%; /* 24px */
      color: rgba(230, 230, 230, 0.7);
      font-family: 'Poppins', sans-serif;
      font-weight: 450;
      text-align: justify;
    }

    // gamifiy trade img
    .gamified-trade-img {
      margin-left: -25%;
    }

    .trade-with-the-best-img {
      margin-right: -12%;
    }
  }

  // Testimonials Section
  // ================================================
  .testimonials-section {
    text-align: center;
    font-family: var(--font-circularstdbook);
    font-size: 20px;
    color: #e6e6e6;
    position: relative;
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    min-height: max-content;

    .liner-shade {
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;
    }

    .container {
      width: 1660px;
      max-width: 1660px;
    }

    h3 {
      font-size: 64px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0;
    }

    p.heading-title {
      padding: 6px 0 0 0;
      margin: 0;
    }

    ul {
      padding: 83px 0;
      margin: 0;
    }

    li {
      border-radius: 24px;
      border: 2px solid rgba(255, 255, 255, 0.1);
      background: rgba(13, 7, 41, 0.8);
      backdrop-filter: blur(20px);

      // background: #0d0729;
      display: inline-block;
      list-style: none;
      padding: 32px;
      // border: 1px solid #2d2949;
      // border-radius: 24px;
      font-family: var(--font-circularstdmedium);
      font-size: 16px;
      color: #e6e6e6;
      font-weight: 300;
      margin: 15px;
      width: 500px;
      text-align: left;
      position: relative;
      overflow: hidden;
    }

    li:hover {
      border-radius: 24px;
      border: 2px solid #846d9f;
      background: rgba(13, 7, 41, 0.8);
      backdrop-filter: blur(20px);
    }

    li:after {
      width: 417px;
      height: 48px;
      border-radius: 417px;
      background: rgba(173, 105, 205, 0.6);
      filter: blur(44.25px);
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
    }

    li:hover:after {
      opacity: 100;
    }

    li p {
      color: #e6e6e6;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; /* 19.2px */
      padding: 0 0 79px 0;
    }

    .bio-box .bio {
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      // font-family: 'slick';
      font-size: 14px;
      font-style: normal;
      font-weight: 450;
      line-height: 120%; /* 16.8px */
    }

    .bio-box strong {
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 24px */
    }

    h5 {
      font-family: var(--font-circularstdmedium);
      font-size: 24px;
      color: #e6e6e6;
      padding: 0;
      margin: 0 0 12px;
      font-weight: 500;
    }

    .bio-box {
      display: flex;
      gap: 12px;
      align-items: center;
    }
  }

  // Road Map with content section
  // ==========================================

  .roadmap-section {
    // padding: 86px 0 35px;
    // padding: 10% 0 25%;
    // background: url(/images_p/roadmap-bg.webp) no-repeat bottom;
    // background-size: contain;

    position: relative;
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    min-height: max-content;

    .liner-shade {
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      // display: flex;
      // position: relative;
    }

    .liner-shade:first-child {
      padding: 168px 0px 0px 0px;
      background: linear-gradient(0deg, rgba(0, 6, 30, 1), rgba(0, 6, 30, 1)),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
    }

    .liner-shade .roadmap-img {
      padding-bottom: 100px;
    }

    h3 {
      font-size: 64px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0 0 12px;
      text-align: center;
    }

    ul {
      padding: 0;
      margin: 40px 0 0;
      text-align: center;
    }

    ul li {
      list-style: none;
      display: inline-block;
      margin: 0 10px;
    }

    ul li a {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      color: #e6e6e6;
      padding: 10.5px 23px;
      border: 2px solid #e6e6e6;
      border-radius: 8px;
    }

    ul li a:hover,
    ul li a.active,
    ul li a.wt-btn:hover {
      border: 2px solid var(--BTN-Stroke, rgba(0, 0, 0, 0.5));
      background: var(
        --BTN-Background,
        linear-gradient(
          180deg,
          rgba(254, 200, 241, 0.9) -22.92%,
          rgba(254, 200, 241, 0) 26.73%
        ),
        radial-gradient(
          137.13% 253.39% at 76.68% 66.67%,
          #602ea6 0%,
          #c977d6 100%
        )
      );
      background-blend-mode: overlay, normal;
      color: #e6e6e6;
    }

    ul li a.wt-btn {
      border: 2px solid #fff;
      background: linear-gradient(
          0deg,
          rgba(174, 122, 196, 0.2) 0%,
          rgba(174, 122, 196, 0.2) 100%
        ),
        #fff;
      color: #040217;
    }

    .roadmap-img img {
      width: -webkit-fill-available;
      width: 100%;
      height: auto;
    }
  }

  .roadmap-content-section {
    font-family: var(--font-circularstdbook);
    font-size: 20px;
    color: #e6e6e6;

    position: relative;
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    min-height: max-content;

    .liner-shade {
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;
      padding: 150px 0;
      place-content: center;
    }

    .container {
      width: 1460px;
      max-width: 1460px;
    }

    .flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    img.responsive {
      width: 100%;
      height: auto;
    }

    .main-container {
      position: relative;
    }

    .prime-trader-bullets {
      position: absolute;
      top: 12%;
    }

    h3 {
      font-size: 64px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0;
    }

    .txt-box p {
      margin-top: 8px;
    }

    .txt-box {
      max-width: 726px;
      margin: 0 57px 0 80px;
    }

    .txt-box a {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      color: #e6e6e6;
      padding: 10.5px 23px;
      border: 1px solid var(--BTN-Stroke, rgba(0, 0, 0, 0.5));
      border-radius: 8px;
      // border: 2px solid var(--BTN-Stroke, rgba(0, 0, 0, 0.5));
      background: var(
        --BTN-Background,
        linear-gradient(
          180deg,
          rgba(254, 200, 241, 0.9) -22.92%,
          rgba(254, 200, 241, 0) 26.73%
        ),
        radial-gradient(
          137.13% 253.39% at 76.68% 66.67%,
          #602ea6 0%,
          #c977d6 100%
        )
      );
      background-blend-mode: overlay, normal;
      display: inline-block;
      // margin-top: 2px;
    }

    .txt-box a:hover {
      background: none;
      border-color: #fff;
    }
  }

  // Ready Trader Section
  // =======================================

  .ready-trader-section {
    /* Create three equal columns that floats next to each other */
    .ready-trader-column {
      h2 {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 3px;
      }

      float: left;
      width: 33.33%;
      padding: 10px 10px 0px 10px;
    }

    @media (max-width: 768px) {
      .ready-trader-row {
        zoom: 0;
      }
      .avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 88;
        position: relative;
      }
      .avatar-content {
        width: 327px;
        margin: 0 auto;

        // visiable in shade
        z-index: 88;
        position: relative;
        text-align: justify;
      }
      .ready-trader-column {
        h2 {
          color: #fff;
          text-align: center;
          font-family: var(--font-circularstdmedium);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        float: left;
        width: 50%;
        padding: 10px 10px 0 10px;
      }
    }

    /* Clear floats after the columns */
    .ready-trader-row:after {
      content: '';
      display: table;
      clear: both;
    }

    // background: url(/images_p/ready-trader-bg.webp) no-repeat center;
    font-family: var(--font-circularstdbook);
    font-size: 20px;
    color: #e6e6e6;
    text-align: center;
    background-size: cover;
    position: relative;
    background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
    background-size: cover;
    min-height: max-content;
    z-index: 12;
    // max-height: 100dvh;

    .liner-shade {
      padding: 168px 0px 0px 0px;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      display: flex;
      position: relative;

      .container {
        padding: 20px 0 225px;
      }

      // @media only screen and (max-width: 768px) {
      //   .container {
      //     padding: 20px 0 225px;
      //     margin-top: -50%;
      //   }
      // }

      // @media only screen and (max-width: 600px) {
      //   .container {
      //     padding: 20px 0 225px;
      //     margin-top: -60%;
      //   }
      // }

      // @media only screen and (max-width: 540px) {
      //   .container {
      //     padding: 20px 0 225px;
      //     margin-top: -70%;
      //   }
      // }

      // @media only screen and (max-width: 414px) {
      //   .container {
      //     padding: 20px 0 225px;
      //     margin-top: -80%;
      //   }
      // }

      // @media only screen and (max-width: 412px) {
      //   .container {
      //     padding: 20px 0 225px;
      //     margin-top: -80%;
      //   }
      // }

      // @media only screen and (max-width: 375px) {
      //   .container {
      //     padding: 20px 0 225px;
      //     margin-top: -20%;
      //   }
      // }

      // @media only screen and (max-width: 360px) {
      //   .container {
      //     padding: 20px 0 225px;
      //     margin-top: -50%;
      //   }
      // }

      // @media only screen and (max-width: 344px) {
      //   .container {
      //     padding: 20px 0 225px;
      //     margin-top: -90%;
      //   }
      // }
    }

    .glow-1,
    .glow-2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      border-radius: 611px; /* Adjust the border-radius to match your requirement */
      opacity: 0.6; /* Adjust the opacity as needed */
      background: var(
        --BTN-Figma,
        linear-gradient(
          180deg,
          rgba(254, 200, 241, 0.9) -22.92%,
          rgba(254, 200, 241, 0) 26.73%
        ),
        radial-gradient(
          137.13% 253.39% at 76.68% 66.67%,
          #602ea6 0%,
          #c977d6 100%
        )
      );
      background-blend-mode: overlay, normal;
      filter: blur(100px); /* Adjust the blur size */
      z-index: 3;
    }

    .glow-1 {
      border-radius: 1889px;
      opacity: 0.6;
      background: linear-gradient(
          180deg,
          rgba(254, 200, 241, 0.9) -22.92%,
          rgba(254, 200, 241, 0) 26.73%
        ),
        radial-gradient(
          137.13% 253.39% at 76.68% 66.67%,
          rgba(22, 213, 230, 0.8) 0%,
          rgba(46, 51, 166, 0.8) 100%
        );
      background-blend-mode: overlay, normal;
      filter: blur(150px);
      margin-top: 150px;
      top: auto;
      bottom: 0;
    }

    .glow-2 {
      border-radius: 1611px;
      opacity: 0.6;
      background: var(
        --BTN-Figma,
        linear-gradient(
          180deg,
          rgba(254, 200, 241, 0.9) -22.92%,
          rgba(254, 200, 241, 0) 26.73%
        ),
        radial-gradient(
          137.13% 253.39% at 76.68% 66.67%,
          #602ea6 0%,
          #c977d6 100%
        )
      );
      background-blend-mode: overlay, normal;
      filter: blur(150px);
      transform: translateX(-50%);
      left: 50%;
      /* margin-top: 0; */
      margin: 0 auto;
      top: 380px;
      max-width: 70%;
    }

    h3 {
      font-size: 64px;
      color: #e6e6e6;
      font-family: var(--font-circularstdmedium);
      padding: 0;
      margin: 0 0 12px;
      text-align: center;
      position: relative;
      z-index: 2222;
    }

    p {
      position: relative;
      z-index: 2222;
    }

    img.responsive {
      width: 100%;
      height: auto;
      position: relative;
      z-index: 10;
    }

    .img-box {
      // margin: 81px 0 0;
    }
  }

  .transparent-background {
    background: transparent !important;
  }

  .footer-section {
    position: relative;
    background: url(/images_p/bg/footer-bg-new.webp) no-repeat top right;
    background-size: cover;
    min-height: max-content;

    .icons-container {
      display: flex;
      flex-direction: column;

      .navigation {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 450;
        line-height: 200%;

        a {
          text-decoration: none;
        }
      }
    }

    .titles {
      font-size: 44px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 8px;
      text-shadow: 0px 0px 25px #91EBFF;
      &.center {
        margin: 0 auto;
      }
    }

    .new-icons-box {
      display: flex;
      gap: 40px;
    }

    .liner-shade {
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgba(0, 6, 30, 0.7),
          rgba(0, 6, 30, 0.7)
        ),
        linear-gradient(
          180deg,
          #00061e 0%,
          rgba(0, 6, 30, 0) 50.5%,
          #00061e 100%
        );
      padding: 149px 60px;
    }

    .container {
      max-width: 1560px;
      width: 100%;
    }

    .footer-link {
      display: flex;
      align-items: center;
      gap: 16px;
      text-decoration: none !important;

      p {
        color: #e6e6e6;
        font-size: 24px;
        font-style: normal;
        font-weight: 450;
        line-height: 200%;
        margin: 0;
      }
    }

    .flex-box {
      display: flex;
      justify-content: space-between;
    }

    .new-flex-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .footer-logo-box {
      max-width: 465px;
      font-family: var(--font-circularstdmedium);
      font-size: 20px;
      color: #e6e6e6;
      align-items: flex-start;
      margin-right: 80px;
      margin-bottom: 40px;
    }

    .footer-passage {
      margin-top: 23px;
      margin-bottom: 55px;
      // font-family: 'slick';
    }

    .footer-logo-box p:last-of-type {
      font-family: 'Poppins', sans-serif;
      width: 621px;
    }

    .copyright {
      color: rgba(230, 230, 230, 0.8);
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
    }

    .footer-links {
      z-index: 2000;
    }

    .footer-links a {
      font-family: var(--font-circularstdmedium);
      // font-family: 'slick';
      font-size: 20px;
      color: #e6e6e6;
      margin-bottom: 20px;
      display: block;
    }

    .footer-links a:hover {
      color: #949292;
    }

    .footer-social-box {
      width: 30%;
      text-align: right;
      display: flex;
      flex-direction: column;
      // align-items: center;
      // align-items: flex-end;
      align-items: flex-start;
    }

    .footer-social-box div {
      display: flex;
      gap: 60px;
    }

    //.footer-social-box a {
    //  margin: 0 30px 24px 0;
    //}
    .footer-social-box a:hover {
      opacity: 0.7;
    }
  }

  // mobile display hide
  .mob-hidden {
    display: block;
  }

  // laptops
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    // top section
    .container {
      padding: 0 40px;
    }

    .get-started-section .flex-box {
      flex-direction: column;
    }

    // middle
    .how-prime-section .spider-graph {
      padding: 0 120px;
      height: auto;
    }

    // footer
    .footer-section .flex-box {
      flex-direction: column;
    }

    .footer-social-box div {
      display: flex;
      gap: 80px;
    }
  }

  // Media Quires
  // ===================================================

  // Desktop
  // ====================================================
  @media (max-width: 1024px) {
    // timer
    .signup-txt {
      text-align: center;
      margin: 0 auto;
    }
    .get-started-btn {
      margin-right: 10%;
    }

    // middle
    .how-prime-section .img-box {
      display: none;
    }
    .countries-box,
    .total-join {
      display: none;
    }
    a.banner-btn {
      margin-top: 20px;
    }
    .banner-img img {
      display: block;
    }
    .banner-img img {
      right: -20px;
      position: relative;
      width: 100%;
      height: auto;
    }

    // main
    .main-banner {
      padding: 0;
    }

    .footer-social-box div {
      display: flex;
      gap: 20px;
    }
  }

  // Laptop high resolution
  // ====================================================
  @media only screen and (min-width: 1030px) and (max-width: 1366px) {
    // top
    // top section
    .container {
      padding: 0 40px;
    }

    // middle
    .spider-graph {
      height: 100% !important;
    }
    .how-prime-section {
      .txt-box {
        max-width: fit-content;
      }

      h3,
      ul {
        max-width: 70% !important;
      }
    }

    // getting started
    .get-started-section {
      img {
        width: 100%;
      }

      ul.signup-list {
        padding: 40px 0 80px 0;
      }
    }

    // footer
    .footer-section {
      .container {
        max-width: fit-content;
      }

      .liner-shade {
        //padding: 0 0 5% 0;
      }

      .flex-box {
        gap: 40px;
      }
    }
  }

  // MDPI Screen
  // ===================================================
  @media screen and (min-width: 1280px) {
    .how-prime-section {
      .container {
        padding: 15% 0;
      }

      .img-box {
        padding: 0 0 0 7%;
      }

      .roadmap-img img {
        width: 100%;
        height: auto;
      }
    }

    .team-members-section {
      .team-bio {
        bottom: -38px;
      }

      .slick-current .team-bio {
        bottom: 0;
      }
    }
  }

  // Desktop Large screen
  // ====================================================
  @media only screen and (min-width: 1370px) and (max-width: 1605px) {
    .container.kol {
      padding: 0 40px !important;
    }
    .roadmap-section .roadmap-img img {
      width: 100%;
      height: auto;
    }

    .how-prime-section {
      .container {
        // padding: 97px 0;
        padding: 120px 0 200px 0;
      }

      .img-box {
        padding: 0;
      }

      h3 {
        max-width: 99%;
      }

      .spider-graph {
        height: auto;
      }
    }

    .roadmap-img img {
      width: 100%;
      height: auto;
    }

    h3 {
      max-width: 99%;
    }

    // // hero section
    // .main-banner .banner-img img {
    //   position: absolute;
    //   top: -7%;
    //   right: 0;
    //   width: 57%;
    //   height: 100%;
    //   object-fit: contain;
    // }
  }

  // Laptop (Macbock etc)
  // ===================================================
  @media screen and (min-width: 1440px) {
    // roadmap section
    .roadmap-content-section .container {
      padding: 0 0 0 6%;
    }

    .roadmap-section .roadmap-img img {
      width: 100%;
      height: auto;
    }

    .how-prime-section {
      .container {
        padding: 12% 0 16% 0;
      }

      .img-box {
        padding-left: 7%;
      }

      img {
        // width: 100%;
        height: auto;
      }

      .countries-box {
        // right: -25px;
        bottom: 4px;
      }
    }

    .pt-eco-system-section .container {
      // max-width: 1350px;
      // max-width: 1280px;
      padding: 150px 10px 0 10px;
    }

    .get-started-section {
      .container {
        max-width: 100dvw;
        padding-right: 24px;
      }

      ul.signup-list {
        margin: 40px 0 0;
      }

      &::after {
        top: 65%;
      }

      .img-box .geting-started-image {
        margin-right: 0%;
        // width: 99%;
      }
    }

    .trading-meets-section {
      img.responsive {
        width: 110%;
        height: auto;
      }

      .img-box img {
        margin-left: 0%;
      }
    }

    .core-team-section {
      .slick-slide img {
        top: 0;
      }

      .slick-current img {
        top: -99px;
      }
    }

    .footer-section .flex-box {
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 1560px) {
    .main-banner .banner-img .Solana_3D {
      bottom: 36%;
      right: 18%;
      width: 380px;
      height: 380px;
    }
    .main-banner .banner-img .Avalanche_3D {
      bottom: 48%;
      right: 12%;
      width: 200px;
      height: 200px;
    }
    .main-banner .banner-img .USD_Coin_3D_1 {
      bottom: 66%;
      right: 12%;
      width: 190px;
      height: 190px;
    }
    .main-banner .banner-img .USD_Coin_3D {
      bottom: 70%;
      right: 19%;
      width: 220px;
      height: 220px;
    }
    .main-banner .banner-img .Polygon_3D {
      bottom: 72%;
      right: 30%;
      width: 165px;
      height: 165px;
    }
    .main-banner .banner-img .Bitcoin_3D {
      bottom: 57%;
      right: 35%;
      width: 220px;
      height: 220px;
    }
  }
  // Large screen
  // ====================================================
  @media screen and (max-width: 1440px) {
    .main-banner .banner-img .Solana_3D {
      bottom: 36%;
      right: 18%;
      width: 380px;
      height: 380px;
    }
    .main-banner .banner-img .Avalanche_3D {
      bottom: 48%;
      right: 12%;
      width: 200px;
      height: 200px;
    }
    .main-banner .banner-img .USD_Coin_3D_1 {
      bottom: 66%;
      right: 12%;
      width: 190px;
      height: 190px;
    }
    .main-banner .banner-img .USD_Coin_3D {
      bottom: 70%;
      right: 19%;
      width: 220px;
      height: 220px;
    }
    .main-banner .banner-img .Polygon_3D {
      bottom: 72%;
      right: 30%;
      width: 165px;
      height: 165px;
    }
    .main-banner .banner-img .Bitcoin_3D {
      bottom: 57%;
      right: 35%;
      width: 220px;
      height: 220px;
    }
    .roadmap-section .roadmap-img img {
      width: 100%;
      height: auto;
    }

    .how-prime-section {
      .img-box {
        // @ask
        padding: 0 0 0 6%;
      }

      h3 {
        max-width: 99%;
      }

      .spider-graph {
        height: auto;
      }

      .container {
        padding: 12% 0 16% 0;
      }

      .img-box {
        padding-left: 7%;
      }

      img {
        width: 100%;
        height: auto;
      }

      .countries-box {
        right: -40px;
        bottom: 4px;
      }
    }

    .roadmap-img img {
      width: 100%;
      height: auto;
    }

    h3 {
      max-width: 99%;
    }

    .pt-eco-system-section .container {
      max-width: 1300px;
      padding: 150px 10px 0 10px;
    }

    .get-started-section {
      .container {
        max-width: 100dvw;
        padding-right: 24px;
      }

      ul.signup-list {
        margin: 40px 0 10px;
      }

      &::after {
        top: 66%;
      }
    }

    .slick-current li {
      padding: 120px 0;
    }
  }

  @media screen and (min-width: 1500px) {
    .footer-section .flex-box {
      justify-content: space-between;
    }
  }

  @media (max-width: 768px) {
    .ready-trader-section .img-box {
      margin: 0px;
    }
    .core-team-section {
      .liner-shade {
        zoom: 0.3;
      }
    }
    .ready-trader-row {
      zoom: 0.4;
    }

    .mob-container {
      max-width: 100%;
      padding: 0px 24px;
    }

    .mob-hidden {
      display: none;
    }

    //  Hero
    .mob-hero {
      // background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
      background: url(/images_p/mob/mob-bg.webp) no-repeat top right;

      background-size: cover;
      min-height: max-content;
      position: relative;

      .liner-shade {
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(0, 6, 30, 0.7),
            rgba(0, 6, 30, 0.7)
          ),
          linear-gradient(
            180deg,
            #00061e 0%,
            rgba(0, 6, 30, 0) 50.5%,
            #00061e 100%
          );
        display: flex;
      }

      .hero-wrapper {
        padding-bottom: 78px;
        width: 102%;

        .banner-img {
          img {
            margin-top: -20px;
            width: 100%;
            height: auto;
          }
        }

        .txt-box {
          font-size: 20px;
          color: #e6e6e6;
          font-family: var(--font-circularstdbook);
          margin-top: -5%;

          h1 {
            color: #e6e6e6;
            font-family: var(--font-circularstdmedium);
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 48px */
            margin: 0 0 4px 0;
          }

          h2 {
            color: #e6e6e6;
            font-family: var(--font-circularstdmedium);
            font-size: 1.45rem;
            font-style: normal;
            font-weight: 500;
            margin: 10px 0 0 0;
          }

          h4 {
            color: #e6e6e6;
            font-family: var(--font-circularstdmedium);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 28.8px */
            margin: 0 0 8px 0;
          }

          p {
            text-align: justify;
            color: #e6e6e6;
            font-family: Jura;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
            margin: 0;
          }

          .slider-bar {
            margin-top: 42px;
            margin-bottom: 42px;
          }

          a.banner-btn {
            margin: 0;
            width: 100%;

            text-align: center;

            color: #fff;
            text-align: center;
            text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
            font-family: var(--font-circularstdmedium);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */

            display: flex;
            height: 45px;
            padding: 14px 26px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;

            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            background: var(
              --BTN-Background,
              linear-gradient(
                180deg,
                rgba(254, 200, 241, 0.9) -22.92%,
                rgba(254, 200, 241, 0) 26.73%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #602ea6 0%,
                #c977d6 100%
              )
            );
            background-blend-mode: overlay, normal;
            box-shadow: 0px -1px 8px 0px #9375b6 inset,
              0px 0px 5px 0px #fface4 inset,
              0px 30px 30px 0px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }

    .mob-trading-meets-section {
      background: url(/images_p/main-section-bg-test.webp) no-repeat;
      background-size: cover;
      // height: 100dvh;
      min-height: max-content;
      position: relative;
      text-align: center;
      color: #ffffff;

      .liner-shade {
        padding: 100px 0 80px;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(0, 6, 30, 0.7),
            rgba(0, 6, 30, 0.7)
          ),
          linear-gradient(
            180deg,
            #00061e 0%,
            rgba(0, 6, 30, 0) 50.5%,
            #00061e 100%
          );
        // z-index: -1;
        display: flex;
        justify-content: center;
        padding-left: 24px;
        padding-right: 24px;
      }

      h2 {
        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdmedium);
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 43.2px */
        margin: 0 0 16px 0;
      }

      .top-meets {
        padding: 16px;
      }

      h3 {
        color: #fff;
        font-family: var(--font-circularstdmedium);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        text-transform: uppercase;
        margin: 0 0 16px 0;
      }

      ul {
        color: #fff;
        text-align: center;
        font-family: Jura;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 165%; /* 23.1px */
      }
    }

    .mob-pt-eco-system-section {
      background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
      background-size: cover;
      // height: 100dvh;
      min-height: max-content;
      position: relative;

      .liner-shade {
        // padding: 200px 0 80px;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(0, 6, 30, 0.7),
            rgba(0, 6, 30, 0.7)
          ),
          linear-gradient(
            180deg,
            #00061e 0%,
            rgba(0, 6, 30, 0) 50.5%,
            #00061e 100%
          );
        // z-index: -1;
        display: flex;
        padding: 0 24px 0 24px;

        .mob-container {
          padding: 50px 10px 0 10px;
          display: flex;
          flex-direction: column;
          gap: 25px;

          label {
            color: #e6e6e6;
            text-align: center;
            text-shadow: 0px 0px 20px #45d3e8;
            font-family: var(--font-circularstdmedium);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 64px */
          }
        }
      }

      .scroll-container {
        overflow-x: auto;
        display: flex;
        scrollbar-color: #8b43bd #8b43bd;
        scrollbar-width: thin;
        -ms-overflow-style: none;
      }

      .scroll-content {
        display: inline-block;

        img {
          width: auto;
          height: 413px;
          padding-bottom: 16px;
        }
      }
    }

    .mob-get-started-section {
      background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
      background-size: cover;
      // height: 100dvh;
      min-height: max-content;
      position: relative;
      color: white;

      .liner-shade {
        // padding: 200px 0 80px;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(0, 6, 30, 0.7),
            rgba(0, 6, 30, 0.7)
          ),
          linear-gradient(
            180deg,
            #00061e 0%,
            rgba(0, 6, 30, 0) 50.5%,
            #00061e 100%
          );
        // z-index: -1;
        display: flex;
        flex-direction: column;
        // padding: 0 12px 0 24px;
        padding-top: 83px;

        .get-started-container {
          padding: 24px 0 0 0;
          text-align: center;
          width: 327px;
          margin: 0 auto;

          h3 {
            color: #e6e6e6;
            text-align: center;
            font-family: var(--font-circularstdmedium);
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 43.2px */
            margin: 0 0 6px 0;
          }

          p {
            color: #e6e6e6;
            text-align: center;
            font-family: Jura;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
            margin: 0;
          }

          .get-started-slider {
            margin: 24px 0 24px 0;

            .slider-bar {
              justify-content: center;
              margin-top: 0;
            }

            svg {
              margin: 0;
              padding: 0;
            }
          }
        }

        .get-started-signup-section {
          display: flex;
          flex-direction: column;
          padding: 0 20px 0 24px;
          align-items: center;
          min-width: 337px;

          h4 {
            text-align: center;
            color: #e6e6e6;
            font-family: var(--font-circularstdbook);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 43.2px */
            margin: 0;

            z-index: 88;
          }

          ul {
            color: #e6e6e6;
            font-family: var(--font-circularstdbook);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            margin: 16px 0 0 0;
            margin-left: 20px;
          }

          li {
            list-style-type: disc;
            margin-bottom: 21px;
            line-height: 21px;
          }

          a {
            display: flex;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            height: 46px;

            // shade
            border-radius: 8px;
            border: 2px solid var(--BTN-Stroke, rgba(0, 0, 0, 0.5));
            background: var(
              --BTN-Background,
              linear-gradient(
                180deg,
                rgba(254, 200, 241, 0.9) -22.92%,
                rgba(254, 200, 241, 0) 26.73%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #602ea6 0%,
                #c977d6 100%
              )
            );
            background-blend-mode: overlay, normal;

            /* BTN Effects */
            box-shadow: 0px -1px 8px 0px #9375b6 inset,
              0px 0px 5px 0px #fface4 inset;

            // text
            color: #e6e6e6;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 14px */
          }

          a:hover {
            background: none;
            border-color: #fff;
          }

          img {
            margin: 24px 0;
          }

          .signupWithGlow {
            position: relative;

            .signupGlowMob {
              svg {
                position: absolute;
                top: 60%;
                left: 20%;
                transform: translate(-50%, -50%);
                height: 520.422px;
                flex-shrink: 0;
              }
            }

            img {
              position: relative;
              z-index: 22;
            }
          }

          .tradeWithGlow {
            position: relative;

            .tradeGlowMob {
              svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 466.422px;
                flex-shrink: 0;
              }
            }

            img {
              position: relative;
              z-index: 22;
            }
          }

          .earnWithGlow {
            position: relative;

            .earnGlowMob {
              svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 520.422px;
                flex-shrink: 0;
              }
            }
          }
        }

        .bridging-the-world {
          margin-top: -100px;
          margin-bottom: 96px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 0 24px;

          h3 {
            color: #e6e6e6;
            text-align: center;
            font-family: var(--font-circularstdmedium);
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 38.4px */
          }

          img {
            width: 100%;
            height: auto;
          }

          p {
            text-align: justify;
            color: #e6e6e6;
            font-family: Jura;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
          }

          h3,
          p {
            margin: 0;
          }

          a {
            display: flex;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            height: 46px;

            //shade
            border-radius: 8px;
            border: 1px solid var(--BTN-Stroke, rgba(0, 0, 0, 0.5));
            background: var(
              --BTN-Background,
              linear-gradient(
                180deg,
                rgba(254, 200, 241, 0.9) -22.92%,
                rgba(254, 200, 241, 0) 26.73%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #602ea6 0%,
                #c977d6 100%
              )
            );
            background-blend-mode: overlay, normal;

            /* BTN Effects */
            box-shadow: 0px -1px 8px 0px #9375b6 inset,
              0px 0px 5px 0px #fface4 inset;

            // text
            color: #e6e6e6;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 14px */
          }
        }

        .bridging-the-world-child {
          margin-top: 0;
          margin-bottom: 96px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 0 24px;

          h3 {
            color: #e6e6e6;
            text-align: center;
            font-family: var(--font-circularstdmedium);
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 38.4px */
          }

          img {
            width: 100%;
            height: auto;
          }

          p {
            text-align: justify;
            color: #e6e6e6;
            font-family: Jura;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
          }

          h3,
          p {
            margin: 0;
          }

          a {
            display: flex;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            height: 46px;

            //shade
            border-radius: 8px;
            border: 1px solid var(--BTN-Stroke, rgba(0, 0, 0, 0.5));
            background: var(
              --BTN-Background,
              linear-gradient(
                180deg,
                rgba(254, 200, 241, 0.9) -22.92%,
                rgba(254, 200, 241, 0) 26.73%
              ),
              radial-gradient(
                137.13% 253.39% at 76.68% 66.67%,
                #602ea6 0%,
                #c977d6 100%
              )
            );
            background-blend-mode: overlay, normal;

            /* BTN Effects */
            box-shadow: 0px -1px 8px 0px #9375b6 inset,
              0px 0px 5px 0px #fface4 inset;

            // text
            color: #e6e6e6;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 14px */
          }
        }
      }
    }

    .mob-testimonials-section {
      text-align: center;
      font-family: var(--font-circularstdbook);
      font-size: 20px;
      color: #e6e6e6;
      position: relative;
      background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
      background-size: cover;
      min-height: max-content;

      .container {
        padding-left: 24px;
        padding-right: 24px;
      }

      h3 {
        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdmedium);
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 43.2px */
        padding: 0;
        margin: 0;
      }

      p.heading-title {
        color: #e6e6e6;
        text-align: center;
        font-family: Jura;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 16.8px */
        padding: 6px 0 0 0;
        margin: 0;
      }

      ul {
        padding: 18px 0;
        margin: 0;
      }

      li {
        border-radius: 24px;
        border: 2px solid rgba(255, 255, 255, 0.1);
        background: rgba(13, 7, 41, 0.8);
        backdrop-filter: blur(20px);

        // background: #0d0729;
        display: inline-block;
        list-style: none;
        padding: 16px;
        // border: 1px solid #2d2949;
        // border-radius: 24px;
        font-family: var(--font-circularstdmedium);
        font-size: 16px;
        color: #e6e6e6;
        font-weight: 300;
        margin-top: 12px;
        // margin-bottom: 14px;
        width: -webkit-fill-available;
        width: 100%;
        text-align: left;
        position: relative;
        overflow: hidden;
      }

      li:hover {
        border-radius: 24px;
        border: 2px solid #846d9f;
        background: rgba(13, 7, 41, 0.8);
        backdrop-filter: blur(20px);
      }

      li:after {
        width: 283px;
        height: 25px;
        border-radius: 283px;
        opacity: 0.5;
        background: rgba(173, 105, 205, 0.6);
        filter: blur(44.25px);
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0;
      }

      li:hover:after {
        opacity: 100;
      }

      li p {
        color: #e6e6e6;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%; /* 19.2px */
        // padding: 0 0 79px 0;
      }

      .bio-box .bio {
        font-family: var(--font-circularstdmedium);
        color: #e6e6e6;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 120%; /* 16.8px */

        /* 19.2px */
      }

      .bio-box strong {
        color: #e6e6e6;
        font-family: var(--font-circularstdmedium);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 24px */
      }

      h5 {
        font-family: var(--font-circularstdmedium);
        padding: 0;
        margin: 0 0 12px;
        color: #e6e6e6;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
        padding-top: 16px;
        border-top: 1px solid
          var(--light_purple_stroe, rgba(255, 255, 255, 0.1));
      }

      .bio-box {
        display: flex;
        gap: 12px;
        align-items: center;
      }

      .acc-head {
        display: flex;
        justify-content: space-between;

        .rotate {
          transform: rotate(180deg);
        }
      }

      .liner-shade {
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(0, 6, 30, 0.7),
            rgba(0, 6, 30, 0.7)
          ),
          linear-gradient(
            180deg,
            #00061e 0%,
            rgba(0, 6, 30, 0) 50.5%,
            #00061e 100%
          );
        display: flex;
        position: relative;
        padding-bottom: 10px;

        .testimonials-container {
          margin: 0 24px 96px 24px;

          .testimonials-mob {
            li {
              display: block;
              width: auto;
              padding: 16px 0;
              font-size: 14px;
            }

            .bio-box {
              margin: 0 16px 16px;
            }

            .bio-box .bio strong {
              font-size: 16px;
            }

            .bio-txt {
              padding: 16px 16px 0;
              border-top: 1px solid #2d2949;
            }

            .bio-txt p {
              display: none;
            }

            .testimonials-mob h5 {
              font-size: 16px;
            }

            .testimonials-mob h5 .icon {
              position: absolute;
              right: 0;
              cursor: pointer;
            }

            .testimonials-mob h5.active .icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .mob-ready-trader-section {
      font-family: var(--font-circularstdbook);
      font-size: 20px;
      color: #e6e6e6;
      text-align: center;
      background-size: cover;
      position: relative;
      background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
      background-size: cover;
      min-height: max-content;
      z-index: 12;
      // max-height: 100dvh;

      .container {
        padding-left: 24px;
        padding-right: 24px;
      }

      .liner-shade {
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(0, 6, 30, 0.7),
            rgba(0, 6, 30, 0.7)
          ),
          linear-gradient(
            180deg,
            #00061e 0%,
            rgba(0, 6, 30, 0) 50.5%,
            #00061e 100%
          );
        display: flex;
        position: relative;
        justify-content: center;
      }

      .glow-1,
      .glow-2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        border-radius: 611px; /* Adjust the border-radius to match your requirement */
        opacity: 0.6; /* Adjust the opacity as needed */
        background: var(
          --BTN-Figma,
          linear-gradient(
            180deg,
            rgba(254, 200, 241, 0.9) -22.92%,
            rgba(254, 200, 241, 0) 26.73%
          ),
          radial-gradient(
            137.13% 253.39% at 76.68% 66.67%,
            #602ea6 0%,
            #c977d6 100%
          )
        );
        background-blend-mode: overlay, normal;
        filter: blur(100px); /* Adjust the blur size */
        z-index: 3;
      }

      .glow-1 {
        border-radius: 1889px;
        opacity: 0.6;
        background: linear-gradient(
            180deg,
            rgba(254, 200, 241, 0.9) -22.92%,
            rgba(254, 200, 241, 0) 26.73%
          ),
          radial-gradient(
            137.13% 253.39% at 76.68% 66.67%,
            rgba(22, 213, 230, 0.8) 0%,
            rgba(46, 51, 166, 0.8) 100%
          );
        background-blend-mode: overlay, normal;
        filter: blur(150px);
        margin-top: 150px;
        top: auto;
        bottom: 0;
      }

      .glow-2 {
        border-radius: 1611px;
        opacity: 0.6;
        background: var(
          --BTN-Figma,
          linear-gradient(
            180deg,
            rgba(254, 200, 241, 0.9) -22.92%,
            rgba(254, 200, 241, 0) 26.73%
          ),
          radial-gradient(
            137.13% 253.39% at 76.68% 66.67%,
            #602ea6 0%,
            #c977d6 100%
          )
        );
        background-blend-mode: overlay, normal;
        filter: blur(150px);
        transform: translateX(-50%);
        left: 50%;
        /* margin-top: 0; */
        margin: 0 auto;
        top: 380px;
        max-width: 70%;
      }

      h3 {
        color: #e6e6e6;
        font-family: var(--font-circularstdmedium);
        padding: 0;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
        margin-bottom: 12px;
      }

      p {
        color: #e6e6e6;
        text-align: center;
        font-family: var(--font-circularstdmedium);
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 120%; /* 16.8px */
        line-height: 120%;
        z-index: 33;
        position: relative;
      }

      img.responsive {
        // width: 100%;
        width: 377px;
        height: auto;
        z-index: 33;
        position: relative;
      }

      .img-box {
        margin: 26px 0 28px 0;
      }
    }

    .mob-footer-section {
      position: relative;
      background: url(/images_p/main-section-bg-test.webp) no-repeat top right;
      background-size: cover;
      min-height: max-content;
      color: #e6e6e6;
      text-align: center;
      // margin-top: 68px;

      .liner-shade {
        width: 100%;
        height: 100%;
        background: linear-gradient(
            0deg,
            rgba(0, 6, 30, 0.7),
            rgba(0, 6, 30, 0.7)
          ),
          linear-gradient(
            180deg,
            #00061e 0%,
            rgba(0, 6, 30, 0) 50.5%,
            #00061e 100%
          );

        padding-bottom: 6%;
      }

      .container {
        max-width: 100dvw;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 80px;
      }

      a img {
        width: 146px;
        height: 58px;
      }

      .footer-passage {
        color: #e6e6e6;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 21px */
        margin-top: 23px;
        margin-bottom: 24px;
      }

      .footer-links {
        display: flex;
        justify-content: center;
        gap: 24px;
        margin-bottom: 55px;
        color: #e6e6e6;
      }

      .footer-links-left,
      .footer-links-right,
      ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        list-style: none;
      }

      .footer-links ul li a {
        color: #e6e6e6;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */
        text-decoration: none;
      }

      .footer-social-box {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        margin-bottom: 55px;
      }

      .footer-icons1,
      .footer-icons2 {
        display: flex;
        flex-direction: column;
        gap: 60px;

        @media (max-width: 767px) {
          flex-direction: row;
        }
      }

      .footer-icons1 img,
      .footer-icons2 img {
        width: 30px;
        height: 30px;
      }

      .copyright {
        color: rgba(230, 230, 230, 0.8);
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }
}
